import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from "./sidebar.metadata";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'app/service/authentication.service';

declare var $: any;
@Component({
    // moduleId: module.id,
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    userinfo: any;
    site_title: string;

    constructor(private router: Router,
        private route: ActivatedRoute, private authService: AuthenticationService, ) {
    }

    ngOnInit() {
        $.getScript('./assets/js/app-sidebar.js');
        this.menuItems = ROUTES.filter(menuItem => menuItem);
        this.authService.getProfile().subscribe(res => {
            this.userinfo = res;
            this.site_title = this.userinfo.siteName;
        });
    }

    onLogoutClick() {
        if (confirm('Are you sure you want to log out?')) {
            this.authService.logout();
            this.router.navigate(['/admin']);
            return false;
        }
    }

}